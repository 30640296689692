import * as React from "react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as style from "../styles/contact.module.scss"

const Contact = ({ location }) => {
  const { state = {} } = location
  const { types } = state
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [error, setError] = useState(false)
  const selectType = {
    apparel: false,
    appliances: false,
    online: false,
  }

  if (typeof types !== "undefined") {
    selectType[types] = true
  }

  const onSubmit = async data => {
    try {
      let params = new URLSearchParams(data)
      const res = await axios.post("/api/mail.php", params)
      setError(res.data.error)
    } catch {
      setError(true)
    }
    if (error === false) {
      window.location.href = "/contact/thanks"
    }
  }
  return (
    <Layout>
      <Seo
        title="Contact | 株式会社アローズ"
        description="私たちができること向かっていく未来。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
      />
      <div id="contact" className={style.contactWrap}>
        <div className={style.head}>
          <div className={style.inner}>
            <h1>CONTACT</h1>
            <h2 className={style.catch}>お問い合せ</h2>
          </div>
        </div>
        <div className={style.contentWrap}>
          <div className={style.formWrap}>
            <div className={style.explanatory}>
              英数字は半角、カタカナは全角で入力お願いします。
              <br />
              個人情報の取り扱いに関しまして、当社プライバシーポリシーをお読み頂き、
              <br />
              「プライバシーポリシーに同意する」をご選択ください。
            </div>
            <div className={style.formBlock}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <dl>
                  <dt>お問い合わせ種類</dt>
                  <dd>
                    <select {...register("types", { required: true })}>
                      <option value="">選択してください</option>
                      {selectType["apparel"] ? (
                        <option
                          value="アパレル事業についてのお問い合わせ"
                          selected
                        >
                          アパレル事業についてのお問い合わせ
                        </option>
                      ) : (
                        <option value="アパレル事業についてのお問い合わせ">
                          アパレル事業についてのお問い合わせ
                        </option>
                      )}
                      {selectType["appliances"] ? (
                        <option value="家電事業についてのお問い合わせ" selected>
                          家電事業についてのお問い合わせ
                        </option>
                      ) : (
                        <option value="家電事業についてのお問い合わせ">
                          家電事業についてのお問い合わせ
                        </option>
                      )}
                      {selectType["online"] ? (
                        <option
                          value="ネット通販事業についてのお問い合わせ"
                          selected
                        >
                          ネット通販事業についてのお問い合わせ
                        </option>
                      ) : (
                        <option value="ネット通販事業についてのお問い合わせ">
                          ネット通販事業についてのお問い合わせ
                        </option>
                      )}
                      <option value="その他のお問い合わせ">
                        その他のお問い合わせ
                      </option>
                    </select>
                    {errors.types && (
                      <div className={style.errors}>
                        ※必須項目です。選択をお願いします。
                      </div>
                    )}
                  </dd>

                  <dt>お問い合わせ内容</dt>
                  <dd>
                    <textarea {...register("inquiry", { required: true })} />
                    {errors.inqiry && (
                      <div className={style.errors}>
                        ※必須項目です。入力をお願いします。
                      </div>
                    )}
                  </dd>

                  <dt>会社名</dt>
                  <dd>
                    <input
                      type="text"
                      {...register("company", { required: true })}
                    />
                    {errors.company && (
                      <div className={style.errors}>
                        ※必須項目です。入力をお願いします。
                      </div>
                    )}
                  </dd>

                  <dt>ご住所</dt>
                  <dd>
                    <input
                      type="text"
                      {...register("address", { required: false })}
                    />
                  </dd>

                  <dt>URL</dt>
                  <dd>
                    <input
                      type="url"
                      {...register("url", { required: false })}
                    />
                  </dd>

                  <dt>お名前</dt>
                  <dd>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <div className={style.errors}>
                        ※必須項目です。入力をお願いします。
                      </div>
                    )}
                  </dd>

                  <dt>フリガナ</dt>
                  <dd>
                    <input
                      type="text"
                      {...register("furigana", {
                        required: true,
                        pattern: /^[ァ-ンヴー 　]+$/,
                      })}
                    />
                    {errors.furigana && (
                      <div className={style.errors}>
                        ※必須項目です。カタカナ（全角）でフリガナの入力をお願いします。
                      </div>
                    )}
                  </dd>
                  <dt>部署名</dt>

                  <dd>
                    <input
                      type="text"
                      {...register("division", { required: false })}
                    />
                  </dd>

                  <dt>TEL</dt>
                  <dd>
                    <input
                      type="tel"
                      {...register("tel", {
                        required: true,
                        pattern: /0[0-9]{1,4}-[0-9]{1,6}(-[0-9]{0,5})?/,
                      })}
                    />
                    {errors.tel && (
                      <div className={style.errors}>
                        ※必須項目です。'-(ハイフン),半角英数字'で電話番号の入力をお願いします。
                      </div>
                    )}
                  </dd>
                  <dt>FAX</dt>
                  <dd>
                    <input
                      type="tel"
                      {...register("fax", { required: false })}
                    />
                  </dd>

                  <dt>Eメール</dt>
                  <dd>
                    <input
                      type="text"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                      })}
                    />
                    {errors.email && (
                      <div className={style.errors}>
                        ※必須項目です。メールアドレスの形式で入力をお願いします。
                      </div>
                    )}
                  </dd>
                </dl>
                <div className={style.checkPrivacy}>
                  <label>
                    <input
                      type="checkbox"
                      {...register("privacy", { required: true })}
                    />
                    プライバシーポリシーに同意する
                  </label>
                  <br />
                  {errors.privacy && (
                    <div className={style.errors}>
                      ※必須項目です。プライバシーポリシーに同意の上チェックをお願いします。
                    </div>
                  )}
                </div>
                <div className={style.submitBtn}>
                  <input
                    type="submit"
                    value="送信する"
                    className={`${style.toDetail} pl_slide`}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
